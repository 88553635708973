import React, { useState, useEffect } from 'react';
import Modal from './QuizModal/Modal';
import './styles/Quiz.css';
import './styles/Register.css';

// Helper function for starting the quiz by registering a user
const startQuizApi = async (username) => {
    const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to start the quiz');
    }
    return response.json();
};

function Register() {
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
    const [isRegistered, setIsRegistered] = useState(Boolean(localStorage.getItem('userId')));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    // Register the user and start the quiz
    const handleRegister = async () => {
        if (username.trim()) {
            try {
                const data = await startQuizApi(username);
                setUserId(data.id);
                setIsRegistered(true);
                localStorage.setItem('username', username);
                localStorage.setItem('userId', data.id);
            } catch (error) {
                setModalMessage(error.message);
                setIsModalOpen(true);
            }
        } else {
            setModalMessage('Please enter a username!');
            setIsModalOpen(true);
        }
    };

    // Reset registration to allow new user registration
    const resetRegistration = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        setUsername('');
        setUserId(null);
        setIsRegistered(false);
    };

    // Listen for changes in localStorage from other tabs
    useEffect(() => {
        const handleStorageChange = () => {
            setUsername(localStorage.getItem('username') || '');
            setUserId(localStorage.getItem('userId') || null);
            setIsRegistered(Boolean(localStorage.getItem('userId')));
        };
        
        // Add and clean up event listener for storage changes
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    return (
        <div className="overlay">

            {isRegistered && 
             <button className='new-register' onClick={resetRegistration}>Register as a new player<br/>تسجيل لاعب جديد</button>
            
            }

            <div className="start-quiz-modal">
                <div className="register-modal">
                    {!isRegistered && 
                        <h1 className='quiz-heading'>Register / التسجيل</h1>
                    }
                    {isRegistered ? (
                        <div>
                            <p className='register-user'>Welcome, "{username}".</p>
                            <p className='register-user'>You can now proceed in your game.</p>
                            <br/>
                            <p className='register-user' style={{direction: 'rtl'}}>حياك, "{username}".</p>
                            <p className='register-user' style={{direction: 'rtl'}}>يمكنك الآن متابعة اللعبة.</p>
                        </div>
                    ) : (
                        <div>
                            <input
                                type="text"
                                placeholder="Enter your name / اكتب اسمك"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <button className='button-enabled' onClick={handleRegister}>Register / سجل</button>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal for error or information messages */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                message={modalMessage} 
            />
        </div>
    );
}

export default Register;
