import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Encrypt from './pages/Encrypt';
import Decrypt from './pages/Decrypt';
import Puzzle from './pages/Puzzle';
import Quiz from './pages/Quiz';
import Register from './pages/Register';
import PDFReader from './pages/PDFReader';
import ImageQuiz from './pages/ImageQuiz';
import Admin from './pages/Admin';
import ImageGallery from './pages/ImageGallery';
import './App.css';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/puzzle" element={<Puzzle />} />
          <Route path="/encrypt" element={<Encrypt />} />
          <Route path="/decrypt" element={<Decrypt />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pdf-reader" element={<PDFReader/>} />
          <Route path="/image-quiz" element={<ImageQuiz/>} />
          <Route path="/admin" element={<Admin/>} />
          <Route path="/image-gallery" element={<ImageGallery/>} />
        </Routes>
    </Router>
  );
}

export default App;
