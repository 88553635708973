import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="modal-close-button">X</button>
        <h1 style={{color: 'white'}} className='message-text-left'>{message}</h1>
      </div>
    </div>
  );
};

export default Modal;
