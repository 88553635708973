import React, { useState, useEffect } from 'react';
import './ResultsPage.css';

function ResultPage() {
    const [results, setResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await fetch(`https://api.qncsa.qa2023.com/api/quiz-users/get-all-results?page=${currentPage}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch results');
                }
                const data = await response.json();
                setResults(data.data); // data.data contains the array of results
                setTotalPages(data.last_page); // data.last_page contains the total number of pages
            } catch (error) {
                console.error('Error fetching results:', error);
            }
        };

        fetchResults();
    }, [currentPage]);

    // Helper function to calculate time difference
    function calculateTime(startTime, endTime) {
        if (!startTime || !endTime) return 'N/A';

        const start = new Date(startTime);
        const end = new Date(endTime);

        // Check if date conversion was successful
        if (isNaN(start.getTime()) || isNaN(end.getTime())) return 'Invalid date';

        // Calculate the difference in milliseconds
        let diff = end.getTime() - start.getTime();

        // Ensure the time difference is not negative
        if (diff < 0) diff = 0;

        const seconds = Math.floor(diff / 1000);
        
        // Check if the duration exceeds 1 hour (3600 seconds)
        if (seconds > 3600) return 'N/A';

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${minutes} min ${remainingSeconds} sec`;
    }

    // Function to handle page change
    function handlePageChange(newPage) {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    }

    return (
        <div className="results-tab">
            <h2 className='result-heading'>Results</h2>
            <table className='result-table'>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Username</th>
                        <th>Score</th>
                        <th>Time</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <tr key={result.id}>
                            <td>{index + 1 + (currentPage - 1) * 10}</td>
                            <td>{result.username}</td>
                            <td>{result.score}</td>
                            <td>{calculateTime(result.quiz_started_at, result.end_time)}</td>
                            <td>{new Date(result.start_time).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button 
                    className='page-control-button' 
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage <= 1}
                >
                    Previous
                </button>
                {currentPage > 1 && (
                    <button
                        className='page-number-button'
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        {currentPage - 1}
                    </button>
                )}
                <button
                    className='page-number-button active'
                    onClick={() => handlePageChange(currentPage)}
                >
                    {currentPage}
                </button>
                {currentPage < totalPages && (
                    <button
                        className='page-number-button'
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        {currentPage + 1}
                    </button>
                )}
                <button 
                    className='page-control-button' 
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default ResultPage;
