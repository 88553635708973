import React, { useEffect } from 'react';
import './Modalen.css';

const Modal = ({ isOpen, onClose, message, duration }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose, duration]);

  if (!isOpen) return null;

  const [englishMessage, arabicMessage] = message.split('\n');
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1 className='success-encrypt-message'>
          <span className='message-text-left'>{englishMessage}</span>
          <span className='message-text-right'>{arabicMessage}</span>
        </h1>
      </div>
    </div>
  );
};

export default Modal;
