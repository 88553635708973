import React, { useState, useEffect, useCallback } from 'react';
import './styles/Quiz.css';

// Helper Functions
const fetchQuestions = async (userId) => {
    const response = await fetch(`https://api.qncsa.qa2023.com/api/questions?userId=${userId}`);
    if (!response.ok) throw new Error('Failed to fetch questions');
    return response.json();
};

const submitQuizApi = async (username, score) => {
    const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users/finish-quiz', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, score })
    });
    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to finish the quiz');
    }
    return response.json();
};

// Main Quiz Component
function Quiz() {
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [quizStarted, setQuizStarted] = useState(false);
    const [quizFinished, setQuizFinished] = useState(false);
    const [quizScore, setQuizScore] = useState(0);
    const [resultMessage, setResultMessage] = useState('');

    // Load questions from API when quiz is started
    useEffect(() => {
        const loadQuestions = async () => {
            if (userId && quizStarted) {
                try {
                    const data = await fetchQuestions(userId);
                    setQuestions(data);
                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            }
        };
        loadQuestions();
    }, [userId, quizStarted]);

    // Update component state based on localStorage changes
    useEffect(() => {
        const handleStorageChange = () => {
            const storedUsername = localStorage.getItem('username');
            const storedUserId = localStorage.getItem('userId');
            
            setUsername(storedUsername || '');
            setUserId(storedUserId || null);

            // Reset quiz if both username and userId are empty
            if (!storedUsername && !storedUserId) {
                resetQuiz();
            }
        };
        
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);


    const updateQuizStartTime = async (username) => {
        const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users/start-quiz-time', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username })
        });
    
        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Failed to start the quiz');
        }
        return response.json();
    };
    

    const startQuiz = async () => {
        if (userId) {
            await updateQuizStartTime(username);
            setQuizStarted(true);
        } else {
            alert('User not registered. Please register first.');
        }
    };

    const selectAnswer = (questionId, answerId) => {
        const question = questions[currentQuestionIndex];
        const selectedAnswer = question.answers.find(answer => answer.id === answerId);
    
        setUserAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: { answerId, isCorrect: selectedAnswer.is_correct }
        }));
    
        if (selectedAnswer.is_correct) {
            setQuizScore(prevScore => prevScore + 1);
        }
    
        // Delay for 1 second to show answer feedback before moving to the next question
        setTimeout(() => {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(prevIndex => prevIndex + 1);
            } else {
                submitQuiz(quizScore + (selectedAnswer.is_correct ? 1 : 0));
            }
        }, 1000); // 1-second delay
    };
    

    const submitQuiz = async (score) => {
        try {
            const data = await submitQuizApi(username, score);
            setResultMessage(`Score/النتيجة \n (${data.score})`);
            setQuizFinished(true);

            setTimeout(() => resetQuiz(), 4000);
        } catch (error) {
            console.error("Error during quiz submission:", error);
            setResultMessage(`Error: ${error.message}`);
            setQuizFinished(true);

            setTimeout(() => resetQuiz(), 4000);
        }
    };

    const resetQuiz = useCallback(() => {
        setUsername('');
        setUserId(null);
        setQuestions([]);
        setCurrentQuestionIndex(0);
        setUserAnswers({});
        setQuizStarted(false);
        setQuizScore(0);
        setQuizFinished(false);
        localStorage.clear();
    }, []);

    if (quizFinished) {
        return (
            <div className="overlay">
                <div className="start-quiz-modal">
                    <h1 className="result-message">{resultMessage}</h1>
                </div>
            </div>
        );
    } else if (!quizStarted) {
        return (
            <div className="overlay">
                <div className="start-quiz-modal">
                    <h1 className='quiz-heading'>Quiz / الاختبار</h1>
                    <button className='button-enabled' onClick={startQuiz} disabled={!username}>
                        Start Quiz
                    </button>
                </div>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];
    return (
        <div className="overlay">
            <div className="modal">
                <div className="container">
                    <h1 className='quiz-heading'>Quiz / الاختبار</h1>
                    {currentQuestion ? (
                        <div style={{ textAlign: 'center' }}>
                            <h2 className='quiz-question-en'>{currentQuestion.text}</h2>
                            <h2 className='quiz-question-ar'>{currentQuestion.text_ar}</h2>
                            <ul className='quiz-options'>
                                {currentQuestion.answers.map(answer => {
                                    const isSelected = userAnswers[currentQuestion.id]?.answerId === answer.id;
                                    const isCorrect = userAnswers[currentQuestion.id]?.isCorrect;

                                    return (
                                        <li key={answer.id}
                                            className={`answer ${isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''}`}
                                            onClick={() => selectAnswer(currentQuestion.id, answer.id)}>
                                            <span className='answer-text-left'>{answer.text}</span>
                                            <span className='answer-text-right'>{answer.text_ar}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : <p>Loading questions...</p>}
                </div>
            </div>
        </div>
    );
}

export default Quiz;
